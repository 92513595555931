body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('https://res.cloudinary.com/dgsbwd2ig/image/upload/v1724057256/Untitled_design_3_nsaoam.jpg') no-repeat center center fixed;
  background-size: cover;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.home {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px;
  border-radius: 10px ;
  max-width: 400px;
  width: 85%;
  top:40%;
  left:48%;
  position: absolute;
  max-height: 80vh; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-right: 2%;
  margin-left: 2%;
}

.home h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.home p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.home input[type="text"] {
  margin: 10px 0;
  padding: 10px;
  width: -webkit-fill-available;
  border: none;
  border-radius: 5px;
}

.home button {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #ffcc00;
  color: black;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home button:hover {
  background-color: #ff9900;
}

.home h2 {
  margin-top: 30px;
  font-size: 1.5rem;
  color: #ffcc00;
}

.home ul {
  list-style: none;
  padding: 0;
  font-size: 1rem;
  text-align: left;
}

.home ul li {
  margin: 10px 0;
}

.home ul li::before {
  content: '✔';
  color: #ffcc00;
  margin-right: 10px;
}
