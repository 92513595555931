/* src/App.css */

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #1c1c1c;
  color: #fff;
  background-image: url('https://res.cloudinary.com/dgsbwd2ig/image/upload/v1724057256/Untitled_design_3_nsaoam.jpg'); /* Add a suitable KBC background image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App {
  text-align: center;
}

.game-room, .home {
  margin-top: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  width: 100%; /* Make the game-room take up 80% of the screen width */
  max-width: 1200px; /* Set a maximum width to prevent it from becoming too wide on very large screens */
  margin-left: auto; /* Center the game-room horizontally */
  margin-right: auto; /* Center the game-room horizontally */
  
  position: absolute; /* Positioning it absolutely */
  top: 40%; /* Position it 50% from the top */
  left: 50%; /* Position it 50% from the left */
  transform: translate(-50%, -50%); /* Center it by transforming it back by 50% of its width and height */
  text-align: center;
  max-height: 80vh;
  overflow-y: auto; /* Enable vertical scrolling */
}

.home {
  top: 25%;
}

.home button:disabled, .options button:disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}

button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 18px;
  background-color: #ffcc00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #1c1c1c;
  font-weight: bold;
  transition: background-color 0.3s;
}

/* Hover effect for large screens */
@media (min-width: 769px) {
  button:hover {
    background-color: #ffaa00;
    color: #fff;
  }
}

.options-row button.correct-answer {
  background-color: #28a745 !important; /* Green for correct answer */
  color: #fff; /* Ensure text is readable */
}

.options-row button.wrong-answer {
  background-color: #dc3545 !important; /* Red for wrong answer */
  color: #fff; /* Ensure text is readable */
}

input {
  padding: 2px;
  margin: 10px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

select {
  padding: 5px;
  margin: 10px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.config, .game, .scores {
  margin-top: 20px;
  margin-bottom: 20px;
}

h1, h2 {
  margin-bottom: 20px;
  color: #ffcc00;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
}

.game h2 {
  font-size: 24px;
}

input[type="text"], input[type="number"] {
  width: 200px;
  text-align: center;
}

/* General Styles for Popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.categories {
  margin-bottom: 20px;
  display: flex;              /* Use flexbox for layout */
  flex-wrap: wrap;         /* Allow items to wrap to the next line */
  justify-content: center;     /* Center items horizontally */
}

.categories label {
  display: flex;              /* Flex display for each label */
  align-items: center;        /* Center items vertically */
  margin-right: 15px;        /* Space between labels horizontally */
  margin-bottom: 10px;       /* Optional: adds space at the bottom of the row */
}

.categories input[type="checkbox"] {
  margin-right: 8px;         /* Space between checkbox and label text */
}

.popup {
  background-color: #222; /* Dark background for popup */
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.popup h2 {
  margin-top: 0;
  font-size: 24px;
  color: #ffcc00; /* KBC yellow */
}

.popup h3 {
  font-size: 20px;
  color: #ffcc00; /* KBC yellow */
  margin: 10px 0;
}

.popup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hit {
  color: #28a745;
}

.miss {
  color:#dc3545;
}

.popup li {
  background: #333;
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
}

.popup li strong {
  color: #ffcc00; /* KBC yellow */
}

.popup button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffcc00; /* KBC yellow */
  color: #1c1c1c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popup button:hover {
  background-color: #ffaa00;
}

/* Styles for the question container */
.question-container {
  margin-bottom: 20px;
}

.question-boundary {
  border: 3px solid #ffcc00; /* KBC yellow border */
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8); /* Slightly transparent black background */
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

/* Container for the options */
.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Default styling for larger screens */
.options-row {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ensure a maximum width for better layout control */
  margin-bottom: 15px;
}

.option-boundary {
  flex: 1;
  margin: 0 10px;
  padding: 15px;
  border: 3px solid #ffcc00; /* KBC yellow border */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6); /* Dark background */
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #ffcc00;
  transition: background-color 0.3s, transform 0.3s;
}

/* Adjust the width of options to ensure even spacing on larger screens */
.options-row .option-boundary {
  width: calc(50% - 20px); /* Ensure two options fit per row */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .options-row {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 5px;
  }

  .options-row .option-boundary {
    width: 100%; /* Make each option take up full width */
    margin: 5px 0; /* Add some margin between the options */
  }
}
