/* Basic styling for the capsule widget */
.capsule-widget {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #ffffff;
    border-radius: 25px;
    color: #000000;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: auto;
    margin: 5px;
    max-width: 250px;
    justify-content: center;
    box-sizing: border-box;
  }


  
  
  /* Icon styling */
  .capsule-widget .capsule-icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  
  /* Text styling */
  .capsule-widget .capsule-text {
    font-size: 16px;
  }
  
  /* Selected state styles */
  .capsule-widget.selected {
    background-color: #ffcc00; /* Accent color on selection */
    border: 3px solid #ff8c00; /* Thicker border on selection */
  }
  
  .capsule-widget.selected .capsule-text {
    font-weight: bold; /* Make text bold when selected */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .capsule-widget {
      font-size: 14px; /* Slightly smaller text on mobile */
      padding: 8px 12px; /* Less padding on smaller screens */
      max-width: 200px; /* Adjust the maximum width */
    }
  
    .capsule-widget .capsule-icon {
      width: 20px;
      height: 20px; /* Slightly smaller icon on mobile */
      margin-right: 8px; /* Less space between icon and text */
    }
  }
  
  @media (max-width: 480px) {
    .capsule-widget {
      font-size: 12px; /* Smaller text on very small screens */
      padding: 6px 10px; /* Adjust padding */
      max-width: 150px; /* Reduce max width */
    }
  
    .capsule-widget .capsule-icon {
      width: 18px;
      height: 18px; /* Further reduce icon size */
      margin-right: 6px; /* Less space between icon and text */
    }
  }
  
  @media (max-width: 320px) {
    .capsule-widget {
      font-size: 10px; /* Very small text on small phones */
      padding: 4px 8px; /* Less padding on very small screens */
      max-width: 120px; /* Reduce max width even more */
    }
  
    .capsule-widget .capsule-icon {
      width: 16px;
      height: 16px; /* Tiny icon on smallest screens */
      margin-right: 4px; /* Reduce margin */
    }
  }
  